import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/util/ele.js' //element-ui
import '@/style/reset.css' //清除默认样式
//富文本
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);
Vue.config.productionTip = false
//导航守卫
router.beforeEach((to, from, next) => {
  if (to.name === 'Dashboard') {
    if (localStorage.getItem('tltz_token') == null) {
      router.replace('/login')
    }
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
