<template>
  <el-container>
    <el-header>
      <div class="header-left">
        <img src="../assets/loginImg/home.png" alt="" />
        <p>呼和浩特市人民检察院青检听证管理平台</p>
      </div>
      <el-dropdown>
        <div class="header-right">
          <p>您好 {{ name }} ，欢迎登陆！</p>
          <div class="loginStatus">
            <img
              src="../assets/zhcpIcon/headIcon.png"
              class="admin_img"
              alt=""
            />
            <p>{{userName}}</p>
            <img
              src="../assets/zhcpIcon/open_down.png"
              class="open_img"
              alt=""
            />
          </div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-circle-close" @click.native="logout"
            >退出登录</el-dropdown-item
          >
          <el-dropdown-item icon="el-icon-edit" @click.native="password"
            >修改密码</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="旧密码" prop="oldpassword">
              <el-input type="password" v-model="ruleForm.oldpassword" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="password1">
              <el-input type="password" v-model="ruleForm.password1" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
          </span>
        </el-dialog>
    <el-container>
      <myaside />
      <el-main>
        <mybreadcrumb />
        <router-view> </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { Message } from "element-ui";
// import md5 from "md5";
// import { updataUserById } from "../api/api";
import {
  updataUserById
} from "@/api/system";
// ,OldPasswordCheck
import myaside from "../components/layout/aside";
import mybreadcrumb from "../components/layout/breadcrumb";
export default {
  name: "xxx",
  components: { myaside, mybreadcrumb },
  data() {
    var validateOldPass = (rule, value, callback) => {
      // let obj = {};
      // obj.oldPassword = md5(value);
      // console.log(md5(value))
      // obj.id = this.id;
      this.userInfo = JSON.parse(window.localStorage.getItem("tltz_userinfo"));
      if(value !== this.userInfo.password){
        callback(new Error('请输入正确的旧密码'));
      }
      // OldPasswordCheck(obj).then((data) => {
      //     if (data.code == 500) {
      //       callback(new Error('请输入正确的旧密码'));
      //     }
      //   })
    };
    var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.password1 !== '') {
            this.$refs.ruleForm.validateField('password1');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      userName: "",
      name: "",
      dialogVisible: false,
      userInfo:{},
      id:null,
      ruleForm:{
        password:"",
        password1:"",
        oldpassword:""
      },
      rules: {
          oldpassword: [
            { validator: validateOldPass, trigger: 'blur' }
          ],
          password: [
            { validator: validatePass, trigger: 'blur' }
          ],
          password1: [
            { validator: validatePass2, trigger: 'blur' }
          ],
        }
    };
  },
  mounted() {
    if (localStorage.getItem("tltz_userinfo") !== null) {
      console.log(JSON.parse(
        window.localStorage.getItem("tltz_userinfo")
      ))
      this.userName = JSON.parse(
        window.localStorage.getItem("tltz_userinfo")
      ).account;
      this.name = JSON.parse(window.localStorage.getItem("tltz_userinfo")).username;
      this.id = JSON.parse(window.localStorage.getItem("tltz_userinfo")).id;
    }
  },
  methods: {
    confirm(){
      let obj = {};
      obj.id = this.id;
      obj.password =this.ruleForm.password;
      // obj.password = md5(this.ruleForm.password);
      obj.account = this.userInfo.account;
      updataUserById(obj).then((data) => {
          if (data.code == 200) {
            this.dialogVisible = false;
            this.$refs["ruleForm"].resetFields();
            Message.success("修改成功！");
          }else {
            Message.error("修改失败请重试");
            this.$refs["ruleForm"].resetFields();
          }
        })

    },
    logout(){
      this.$router.push("/login");
      localStorage.clear();
    },
    password(){
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  },
};
</script>
<style lang='less'>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 70px;
  height: 70px !important;
  background: #044bfe;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
}

.el-container {
  height: 100%;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.el-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-left {
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
     // width: 43px;
      height: 45px;
      margin: 0 11px 0 22px;
    }
    > p {
      display: inline-block;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      // line-height: 28px;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    > p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-right: 43px;
    }
    > div {
      display: flex;
      align-items: center;
      .admin_img {
        width: 34px;
        height: 35px;
      }
      > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin: 0 10px 0 10px;
      }
      .open_img {
        width: 14px;
        height: 9px;
      }
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
}
</style>