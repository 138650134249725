import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

export const otherRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'dashboard',
    name: 'DashboardAll',
    meta: {
      title: '控制台',
      requireAuth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          title: '控制台',
          groupName: '控制台'
        },
        component: () => import('../views/Dashboard.vue')
      },
    ],
  },
  {
    path: '/organize',
    component: Layout,
    redirect: '/organize/procurator',
    name: 'Organize',
    meta: {
      title: "用户管理",
    },
    children: [
      {
        path: '/organize/procurator',
        name: 'Organization',
        meta: {
          title: "检察院用户管理",
          groupName: '用户管理'

        },
        component: () => import('../views/institutional/procurator.vue')
      },
      {
        path: '/organize/expert',
        name: 'Policemen',
        meta: {
          title: "听证员管理",
          groupName: '用户管理'

        },
        component: () => import('../views/institutional/expert.vue')
      },
    ],
  },
  {
    path: '/hearing',
    component: Layout,
    redirect: '/hearing',
    name: 'ActivityAll',
    meta: {
      title: "听证管理",
    },
    children: [
      {
        path: '/hearing',
        name: 'Hearing',
        meta: {
          title: '听证信息',
          groupName: '听证管理'

        },
        component: () => import('../views/hearing/index.vue')
      },
    ],
  },
  {
    path: '/message',
    component: Layout,
    redirect: '/message/notices',
    name: 'Message',
    meta: {
      title: "资讯管理",
    },
    children: [
      {
        path: '/message/notices',
        name: 'Notices',
        meta: {
          title: "通知公告",
          groupName: '资讯管理'

        },
        component: () => import('../views/message/notices.vue')
      },
      {
        path: '/message/news',
        name: 'News',
        meta: {
          title: "新闻资讯",
          groupName: '资讯管理'

        },
        component: () => import('../views/message/news.vue')
      },
      {
        path: '/message/problem',
        name: 'Problem',
        meta: {
          title: "常见问题",
          groupName: '资讯管理'

        },
        component: () => import('../views/message/problem.vue')
      },
      {
        path: '/message/about',
        name: 'About',
        meta: {
          title: "关于我们",
          groupName: '资讯管理'

        },
        component: () => import('../views/message/about.vue')
      },
    ],
  },

  {
    path: '/statistics',
    component: Layout,
    redirect: '/statistics/hearing',
    name: 'Statistics',
    meta: {
      title: "查询统计",
    },
    children: [
      {
        path: '/statistics/hearing',
        name: 'statisticsHear',
        meta: {
          title: '听证信息查询',
          groupName: '查询统计'

        },
        component: () => import('../views/statistics/index.vue')
      },
      {
        path: '/statistics/expert',
        name: 'StatisticsExpert',
        meta: {
          title: '听证员查询',
          groupName: '查询统计'
        },
        component: () => import('../views/statistics/expert.vue')
      },
    ],
  },
  {
    path: '/system',
    component: Layout,
    redirect: '/system/user',
    name:"System",
    meta: {
      title: "系统管理",
    },
    children: [
      {
        path: '/system/user',
        name: 'UserSystem',
        meta: {
          groupName: '系统管理',
          title: '用户管理'
        },
        component: () => import('../views/system/index.vue')
      },
      {
        path: '/system/permission',
        name: 'Permission',
        meta: {
          groupName: '系统管理',
          title: '角色管理'
        },
        component: () => import('../views/system/permission.vue')
      },
      {
        path: '/system/procuratorate',
        name: 'Procuratorate',
        meta: {
          title: "检察院管理",
          groupName: '选项管理'

        },
        component: () => import('../views/system/procuratorate.vue')
      },
      {
        path: '/system/department',
        name: 'Department',
        meta: {
          title: "部门管理",
          groupName: '选项管理'

        },
        component: () => import('../views/system/department.vue')
      },
      {
        path: '/system/major',
        name: 'Major',
        meta: {
          title: "专家领域",
          groupName: '选项管理'

        },
        component: () => import('../views/system/major.vue')
      },
      {
        path: '/system/case',
        name: 'Case',
        meta: {
          title: "案件类型",
          groupName: '系统管理'

        },
        component: () => import('../views/system/case.vue')
      }


    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/404',
    name: 'unAccess',
    component: () => import('../views/404.vue')
  },

]
export const routes = [
  {
    path: '/',
    component: Layout,
    redirect: 'dashboard',
    name: 'DashboardAll',
    meta: {
      title: '控制台',
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          title: '控制台',
          groupName: '控制台'
        },
        component: () => import('../views/Dashboard.vue')
      },
    ],
  },
  {
    path: '/organize',
    component: Layout,
    redirect: '/organize/procurator',
    name: 'Organize',
    meta: {
      title: "用户管理",
    },
    children: [
      {
        path: '/organize/procurator',
        name: 'Organization',
        meta: {
          title: "检察院用户管理",
          groupName: '用户管理'

        },
        component: () => import('../views/institutional/procurator.vue')
      },
      {
        path: '/organize/expert',
        name: 'Policemen',
        meta: {
          title: "听证员管理",
          groupName: '用户管理'

        },
        component: () => import('../views/institutional/expert.vue')
      },
    ],
  },
  {
    path: '/hearing',
    component: Layout,
    redirect: '/hearing',
    name: 'Hearing',
    meta: {
      title: "听证管理",
    },
    children: [
      {
        path: '/hearing',
        name: 'Project',
        meta: {
          title: '听证信息',
          groupName: '听证管理'

        },
        component: () => import('../views/hearing/index.vue')
      },

    ],
  },
  {
    path: '/message',
    component: Layout,
    redirect: '/message/notices',
    name: 'Message',
    meta: {
      title: "资讯管理",
    },
    children: [
      {
        path: '/message/notices',
        name: 'Notices',
        meta: {
          title: "通知公告",
          groupName: '资讯管理'

        },
        component: () => import('../views/message/notices.vue')
      },
      {
        path: '/message/news',
        name: 'News',
        meta: {
          title: "新闻资讯",
          groupName: '资讯管理'

        },
        component: () => import('../views/message/news.vue')
      },
      {
        path: '/message/problem',
        name: 'Problem',
        meta: {
          title: "常见问题",
          groupName: '资讯管理'

        },
        component: () => import('../views/message/problem.vue')
      },
      {
        path: '/message/publish',
        name: 'Publish',
        meta: {
          title: "发布",
          groupName: '资讯管理'

        },
        component: () => import('../views/message/publish.vue')
      },
      {
        path: '/message/about',
        name: 'About',
        meta: {
          title: "关于我们",
          groupName: '资讯管理'

        },
        component: () => import('../views/message/about.vue')
      },
    ],
  },


  {
    path: '/statistics',
    component: Layout,
    redirect: '/statistics/hearing',
    name: 'Statistics',
    meta: {
      title: "查询统计",
    },
    children: [
      {
        path: '/statistics/hearing',
        name: 'statisticsHear',
        meta: {
          title: '听证信息查询',
          groupName: '查询统计'

        },
        component: () => import('../views/statistics/index.vue')
      },
      {
        path: '/statistics/expert',
        name: 'StatisticsExpert',
        meta: {
          title: '听证员查询',
          groupName: '查询统计'
        },
        component: () => import('../views/statistics/expert.vue')
      },
    ],
  },
  {
    path: '/system',
    component: Layout,
    redirect: '/system/user',
    name:"System",
    meta: {
      title: "系统管理",
    },
    children: [
      {
        path: '/system/user',
        name: 'SystemUser',
        meta: {
          groupName: '系统管理',
          title: '用户管理'
        },
        component: () => import('../views/system/index.vue')
      },
      {
        path: '/system/permission',
        name: 'Permission',
        meta: {
          groupName: '系统管理',
          title: '角色管理'
        },
        component: () => import('../views/system/permission.vue')
      },
      {
        path: '/system/procuratorate',
        name: 'Procuratorate',
        meta: {
          title: "检察院管理",
          groupName: '选项管理'

        },
        component: () => import('../views/system/procuratorate.vue')
      },
      {
        path: '/system/department',
        name: 'Department',
        meta: {
          title: "部门管理",
          groupName: '系统管理'

        },
        component: () => import('../views/system/department.vue')
      },
      {
        path: '/system/major',
        name: 'Major',
        meta: {
          title: "专家领域",
          groupName: '系统管理'

        },
        component: () => import('../views/system/major.vue')
      },
      {
        path: '/system/case',
        name: 'Case',
        meta: {
          title: "案件类型",
          groupName: '系统管理'

        },
        component: () => import('../views/system/case.vue')
      }

    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/404',
    name: 'unAccess',
    component: () => import('../views/404.vue')
  },
  


]
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
})


export default router
