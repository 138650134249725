<template>
  <el-aside width="257px"
    ><el-menu
      background-color="#001529"
      text-color=" #C4C4C4"
      active-text-color="#ffffff"
      router
      :default-active="$route.path"
    >
      <el-submenu
        :index="index + ''"
        v-for="(item, index) in navList"
        :key="item.groupName"
      >
        <template slot="title">
          <img v-if="item.meta.title =='控制台'"
            src="../../assets/zhcpIcon/aside/控制台.png"
            class="aside_icon"
            alt=""
          />
          <img v-if="item.meta.title =='用户管理'"
            src="../../assets/zhcpIcon/aside/用户管理.png"
            class="aside_icon"
            alt=""
          />
            <img v-if="item.meta.title =='听证管理'"
            src="../../assets/zhcpIcon/aside/听证管理.png"
            class="aside_icon"
            alt=""
          />
          <img v-if="item.meta.title =='资讯管理'"
            src="../../assets/zhcpIcon/aside/资讯管理.png"
            class="aside_icon"
            alt=""
          />
          <img v-if="item.meta.title =='系统管理'"
            src="../../assets/zhcpIcon/aside/系统管理.png"
            class="aside_icon"
            alt=""
          />
          <span slot="title">{{ item.children[0].meta.groupName }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            :index="k.path"
            v-for="k in item.children"
            :key="k.meta.title"
            ><span class="dot_icon"></span>{{ k.meta.title }}</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
import { otherRoutes } from "../../router/index";
export default {
  name: "myaside",
  components: {},
  data() {
    return {
      tem: [],
    };
  },

  methods: {},
  computed: {
    /* 
    id 
    2 检察院用户管理
    3 听证员管理
    55 通知公告
    59 新闻资讯
    63 常见问题
    67 关于我们
    69 听证项目
    74 听证项目管理
    76 听证员查询
    78 后台用户管理
    82 角色管理
    86 检察院管理
    90 部门管理
    94 专家领域
    98 案件类型

    */



    //导航 循环权限限制路由
    navList() {
      console.log(otherRoutes);
      console.log(window.localStorage.getItem("permissionidList"))
      let list = JSON.parse(window.localStorage.getItem("permissionidList"))
      // console.log(list,'listlist')
      // let list = ["2","4","5","6","3","8","9","10","69","71","74","55","56","57","58","59","60","61","62","63","64","65","66","67","68","76","78","79","80","81","82","83","84","85","86","88","90","91","92","94","95","96","97","98","99","100","101"]
      let tem = [];
      tem.push(otherRoutes[0])
      console.log(tem)
      list.forEach((item) => {
        if(item == 1) {
          let res = tem.filter((val) => val.meta.title == "用户管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[1].component,
              meta: otherRoutes[1].meta,
              name: otherRoutes[1].name,
              path: otherRoutes[1].path,
              redirect: otherRoutes[1].children[0].path,
              children: [otherRoutes[1].children[0]]
            });
          }else {
            res[0].children.push(otherRoutes[1].children[0]);
          }
        }
        if(item == 2) {
          let res = tem.filter((val) => val.meta.title == "用户管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[1].component,
              meta: otherRoutes[1].meta,
              name: otherRoutes[1].name,
              path: otherRoutes[1].path,
              redirect: otherRoutes[1].children[1].path,
              children: [otherRoutes[1].children[1]]
            });
          }else {
            res[0].children.push(otherRoutes[1].children[1]);
          }
        }
        if(item == 9) {
          let res = tem.filter((val) => val.meta.title == "听证管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[2].component,
              meta: otherRoutes[2].meta,
              name: otherRoutes[2].name,
              path: otherRoutes[2].path,
              redirect: otherRoutes[2].children[0].path,
              children: [otherRoutes[2].children[0]]
            });
          }
        }
        if(item == 11) {
          let res = tem.filter((val) => val.meta.title == "资讯管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[3].component,
              meta: otherRoutes[3].meta,
              name: otherRoutes[3].name,
              path: otherRoutes[3].path,
              redirect: otherRoutes[3].children[0].path,
              children: [otherRoutes[3].children[0]]
            });
          }else {
            res[0].children.push(otherRoutes[3].children[0]);
          }
        }
        if(item == 15) {
          let res = tem.filter((val) => val.meta.title == "资讯管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[3].component,
              meta: otherRoutes[3].meta,
              name: otherRoutes[3].name,
              path: otherRoutes[3].path,
              redirect: otherRoutes[3].children[1].path,
              children: [otherRoutes[3].children[1]]
            });
          }else {
            res[0].children.push(otherRoutes[3].children[1]);
          }
        }
        if(item == 19) {
          let res = tem.filter((val) => val.meta.title == "资讯管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[3].component,
              meta: otherRoutes[3].meta,
              name: otherRoutes[3].name,
              path: otherRoutes[3].path,
              redirect: otherRoutes[3].children[2].path,
              children: [otherRoutes[3].children[2]]
            });
          }else {
            res[0].children.push(otherRoutes[3].children[2]);
          }
        }
        if(item == 23) {
          let res = tem.filter((val) => val.meta.title == "资讯管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[3].component,
              meta: otherRoutes[3].meta,
              name: otherRoutes[3].name,
              path: otherRoutes[3].path,
              redirect: otherRoutes[3].children[3].path,
              children: [otherRoutes[3].children[3]]
            });
          }else {
            res[0].children.push(otherRoutes[3].children[3]);
          }
        }
        
       
        if(item == 25) {
          let res = tem.filter((val) => val.meta.title == "系统管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[5].component,
              meta: otherRoutes[5].meta,
              name: otherRoutes[5].name,
              path: otherRoutes[5].path,
              redirect: otherRoutes[5].children[0].path,
              children: [otherRoutes[5].children[0]]
            });
          }else {
            res[0].children.push(otherRoutes[5].children[0]);
          }
        }
        if(item == 29) {
          let res = tem.filter((val) => val.meta.title == "系统管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[5].component,
              meta: otherRoutes[5].meta,
              name: otherRoutes[5].name,
              path: otherRoutes[5].path,
              redirect: otherRoutes[5].children[1].path,
              children: [otherRoutes[5].children[1]]
            });
          }else {
            res[0].children.push(otherRoutes[5].children[1]);
          }
        }
        if(item == 33) {
          let res = tem.filter((val) => val.meta.title == "系统管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[5].component,
              meta: otherRoutes[5].meta,
              name: otherRoutes[5].name,
              path: otherRoutes[5].path,
              redirect: otherRoutes[5].children[2].path,
              children: [otherRoutes[5].children[2]]
            });
          }else {
            res[0].children.push(otherRoutes[5].children[2]);
          }
        }
        if(item == 36) {
          let res = tem.filter((val) => val.meta.title == "系统管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[5].component,
              meta: otherRoutes[5].meta,
              name: otherRoutes[5].name,
              path: otherRoutes[5].path,
              redirect: otherRoutes[5].children[3].path,
              children: [otherRoutes[5].children[3]]
            });
          }else {
            res[0].children.push(otherRoutes[5].children[3]);
          }
        }
        if(item == 39) {
          let res = tem.filter((val) => val.meta.title == "系统管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[5].component,
              meta: otherRoutes[5].meta,
              name: otherRoutes[5].name,
              path: otherRoutes[5].path,
              redirect: otherRoutes[5].children[4].path,
              children: [otherRoutes[5].children[4]]
            });
          }else {
            res[0].children.push(otherRoutes[5].children[4]);
          }
        }
        if(item == 43) {
          let res = tem.filter((val) => val.meta.title == "系统管理");
          if (!res.length) {
            tem.push({
              component: otherRoutes[5].component,
              meta: otherRoutes[5].meta,
              name: otherRoutes[5].name,
              path: otherRoutes[5].path,
              redirect: otherRoutes[5].children[5].path,
              children: [otherRoutes[5].children[5]]
            });
          }else {
            res[0].children.push(otherRoutes[5].children[5]);
          }
        }
       
      })
      
      const temp = tem
      // console.log(temp)
      return temp;

    },

    //导航 循环路由
    // navList() {
    //   console.log(otherRoutes);
    //   otherRoutes.forEach((item) => {
    //     let ary = item.children;
    //     let temp = [];
    //     ary.forEach((item) => {
    //       let res = temp.filter((val) => val.groupName == item.meta.groupName);
    //       if (!res.length) {
    //         temp.push({
    //           groupName: item.meta.groupName,
    //           list: [item],
    //         });
    //       } else {
    //         res[0].list.push(item);
    //       }
    //     });
    //     this.tem = temp;
    //     return temp;
    //   });

    //   return otherRoutes;
    // },
  },
};
</script>
<style lang='less' >
.el-menu {
  height: 100%;
}
.el-submenu__icon-arrow {
  display: none;
}
.aside_icon {
  width: auto;
  height: 20px;
  margin-right: 16px;
}
.el-submenu__title {
  font-size: 16px !important;
  font-family: Microsoft YaHei;
  padding-left: 27px !important;
  text-align: left;
}
.el-menu-item {
  text-align: left;
  padding-left: 55px !important;
}
.dot_icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-right: 7px;
}
.el-menu-item.is-active {
  background-color: #2d8cf0 !important;
}
// .el-menu{
//   >.el-submenu:first-child{
//     display: none;
//   }
// }
</style>